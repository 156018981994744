import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Gcp from '../../containers/Services/Gcp';

const gcpSections = [
  {
    title: 'Cloud Evaluation',
    summary:
      'Begin your cloud journey with a comprehensive evaluation of your current infrastructure. Our experts assess your needs to develop a strategic roadmap for leveraging GCP’s powerful capabilities, ensuring a seamless transition to the cloud that aligns with your business objectives.',
  },
  {
    title: 'Migration to GCP',
    summary:
      'Migrate with confidence as our team navigates you through the complexities of moving your applications, data, and workloads to GCP. Experience minimal downtime and optimized costs with our proven migration strategies, tailored to meet the unique demands of your business',
  },
  {
    title: 'Hybrid Cloud Solutions',
    summary:
      'Embrace the flexibility of a hybrid cloud approach with Tech Holding. We design and implement solutions that integrate GCP with your on-premises infrastructure, allowing for a balanced, secure, and efficient computing environment that supports both cloud-native and traditional workloads.',
  },
  {
    title: 'Data Management and Analytics',
    summary:
      'Transform data into actionable insights with our data management and analytics services. From data warehousing and big data solutions to advanced analytics and BI tools on GCP, we empower you to make informed decisions that propel your business forward.',
  },
  {
    title: 'Machine Learning and AI',
    summary:
      'Unlock the potential of ML and AI with Tech Holding and GCP. Our experts leverage Google’s cutting-edge AI and machine learning services to build intelligent applications that enhance customer experiences, optimize operations, and drive innovation.',
  },
];

const whyChooseGcp = [
  {
    title: 'Certified Expertise',
    summary:
      'Our global team of GCP-certified engineers brings a deep understanding of Google Cloud technologies, ensuring your projects are led by knowledgeable and experienced professionals.',
  },
  {
    title: 'Custom Solutions',
    summary:
      "At Tech Holding, we believe in a personalized approach. Our services are tailored to meet your specific needs, whether you're just starting your cloud journey or looking to innovate with advanced cloud solutions.",
  },
  {
    title: 'Proven Track Record',
    summary:
      "With multiple successful GCP projects across various industries, our proven track record speaks for itself. We're committed to delivering excellence and achieving tangible results for our clients.",
  },
  {
    title: 'Global Reach, Local Insight',
    summary:
      'Our global presence enables us to offer the scale and expertise of a large firm, combined with the local insight and personalized service of a boutique consultancy.',
  },
];

const title = 'GCP Professional Services at Tech Holding';
const whyChooseTitle = 'Why Choose Tech Holding as Your GCP Partner?';

const contactUsContent = 'Get Started with Tech Holding';
const contactUsSummary =
  'Contact Tech Holding today to discuss how our GCP partnership can deliver the solutions you need for a successful digital transformation.';

const description =
  'As a certified Google Cloud Platform (GCP) Partner, Tech Holding stands at the forefront of cloud innovation, offering a comprehensive suite of services designed to transform your business for the digital age. Our global team of GCP-certified engineers harnesses a wealth of expertise to deliver solutions that drive efficiency, scalability, and groundbreaking advancements.';

const details = {
  title,
  whyChooseTitle,
  description,
  gcpSections,
  whyChooseGcp,
  contactUsContent,
  contactUsSummary,
};

const propTypes = {
  data: PropTypes.shape({
    contentfulCompanyQuote: PropTypes.shape({
      footerDescription: PropTypes.shape({}).isRequired,
    }),
  }).isRequired,
};

const GcpPage = ({ data }) => <Gcp details={details} companyQuote={data.contentfulCompanyQuote} />;

GcpPage.propTypes = propTypes;

export default GcpPage;

export const query = graphql`
  query {
    contentfulCompanyQuote(label: { eq: "servicesAws" }) {
      footerDescription {
        json
      }
    }
  }
`;
