import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Layout from '../../../components/Layout';
import Button from '../../../components/Button';
import { getSeoConfig } from '../../../constants/defaultSeoProps';
import HeaderImage from './img/header-image.png';
import BadgeImage from './img/badge-image.png';

import s from './Gcp.module.scss';

const propTypes = {
  details: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    whyChooseTitle: PropTypes.string.isRequired,
    contactUsContent: PropTypes.string.isRequired,
    contactUsSummary: PropTypes.string.isRequired,
    gcpSections: PropTypes.array.isRequired,
    whyChooseGcp: PropTypes.array.isRequired,
  }).isRequired,
  companyQuote: PropTypes.shape({
    footerDescription: PropTypes.shape({}).isRequired,
  }).isRequired,
};

const seoParams = {
  ogImagePath: '/og-images/gcp.png',
  ogTitle: "Empower Your Business with Tech Holding's GCP Services",
  ogDesc:
    'Tech Holding, a certified Google Cloud Platform Partner, leads cloud innovation with transformative services tailored for the digital era.',
  ogPath: '/services/gcp',
};

const updatedSeo = getSeoConfig(seoParams);

const Gcp = ({ details, companyQuote }) => {
  const {
    title,
    whyChooseTitle,
    description,
    gcpSections,
    whyChooseGcp,
    contactUsContent,
    contactUsSummary,
  } = details;
  return (
    <Layout
      currentPage="/services/gcp"
      seo={updatedSeo}
      title={updatedSeo.openGraph.title}
      footerDescription={companyQuote.footerDescription}
    >
      <div className={s.bgGray}>
        <div className={s.headerContainer} data-test="headerContainer">
          <div className={s.headerContent}>
            <div className={s.title}>
              <h2 data-test="title">{title}</h2>
            </div>
            <div className={s.image} data-test="gcpPartnerNetworkLogo">
              <img
                className={s.emoji}
                src={HeaderImage}
                alt="Google Cloud Partner"
                title="Google Cloud Partner"
              />
            </div>
          </div>
        </div>
      </div>
      <div className={s.pageContainer} data-test="pageContainer">
        <div className={s.gcpContent}>
          <div className={s.content}>
            <p>{description}</p>
          </div>
          <div className={s.gcpBadges}>
            <div data-test="image">
              <img
                className={s.emoji}
                src={BadgeImage}
                alt="GCP Certification Badge"
                title="GCP Certification Badge"
              />
            </div>
          </div>
        </div>
        <div className={s.gcpSectionsContainer}>
          {gcpSections.map((gcpSection) => (
            <div className={s.gcpSections} key={gcpSection.title} data-test="gcpSections">
              <div className={s.content}>
                <h2>{gcpSection.title}</h2>
                <p>{gcpSection.summary}</p>
              </div>
            </div>
          ))}
        </div>
        <div className={s.whyChoose}>
          <h2>{whyChooseTitle}</h2>
          <div className={s.gcpSectionsContainer}>
            {whyChooseGcp.map((gcpSection) => (
              <div className={s.gcpSections} key={gcpSection.title} data-test="whyChooseSnowflake">
                <div className={s.content}>
                  <h3>{gcpSection.title}</h3>
                  <p>{gcpSection.summary}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className={s.contactUsContent}>
        <div className={s.contactUsText}>
          <h2>{contactUsContent}</h2>
          <p className={s.contactUsSummary}>{contactUsSummary}</p>
        </div>
        <div className={s.button}>
          <Link to={`/contact`} title="Contact us">
            <Button theme="grey">Contact Us</Button>
          </Link>
        </div>
      </div>
    </Layout>
  );
};

Gcp.propTypes = propTypes;
export default Gcp;
